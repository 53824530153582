import type { CompanySizeOptionsObject } from "../../translation-keys/company-size-option-keys.js";

const FormCompanySizeOptions: CompanySizeOptionsObject = {
  "1 (GT 10,000 emp)": "Plus de 10 000 employés",
  "2 (5,000-10,000 emp)": "5 000 - 9 999 employés",
  "3 (1,000-5,000 emp)": "1 000 - 4 999 employés",
  "4 (500-1,000 emp)": "500 - 999 employés",
  "5 (100-500 emp)": "100 - 499 employés",
  "6 (50-100 emp)": "50 - 99 employés",
  "7 (50-25 emp)": "25 - 49 employés",
  "8 (LT 25 emp)": "0 - 24 employés",
};

export default FormCompanySizeOptions;
